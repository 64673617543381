var mobileMenu = document.getElementsByClassName('js-mobile-menu')[0],
    navigation = document.getElementsByClassName('js-menu')[0],
    slider = {
        amount: document.getElementsByClassName('js-partner').length,
        container: document.getElementsByClassName('js-partner-container')[0],
        section: document.getElementsByClassName('js-partners-section')[0],
        arrows: document.getElementsByClassName('js-swipe'),
        currentIndex: 0
    },
    body = document.body,
    header = document.getElementsByClassName('header')[0],
    closeMenu = document.getElementsByClassName('js-close-menu')[0],
    toggleLogoClass = function () {
        if (window.pageYOffset || document.documentElement.scrollTop > 0) {
            header.classList.add('scrolled');
        } else {
            header.classList.remove('scrolled');
        }
    },
    swipePartners = function (direction) {
        if ((slider.currentIndex === 0 && direction === -1) ||
            (direction === 1 && slider.currentIndex === (slider.amount - 1))) return;
        slider.currentIndex += direction;
        slider.container.style.transform = 'translateX(-' + slider.currentIndex * 100 + 'vw)';
        updateSwipeArrows();
    },
    updateSwipeArrows = function () {
        slider.section.classList.remove('first');
        slider.section.classList.remove('last');
        if (slider.currentIndex === (slider.amount - 1)) slider.section.classList.add('last');
        if (slider.currentIndex === 0) slider.section.classList.add('first');
    },
    detectSwipe = function (el, callback) {
        var swipe_det = {};
        swipe_det.sX = 0;
        swipe_det.sY = 0;
        swipe_det.eX = 0;
        swipe_det.eY = 0;
        var min_x = 30;
        var max_x = 30;
        var min_y = 50;
        var max_y = 60;
        var direc = "";
        el.addEventListener('touchstart', function (e) {
            var t = e.touches[0];
            swipe_det.sX = t.screenX;
            swipe_det.sY = t.screenY;
        }, false);
        el.addEventListener('touchmove', function (e) {
            // e.preventDefault();
            var t = e.touches[0];
            swipe_det.eX = t.screenX;
            swipe_det.eY = t.screenY;
        }, false);
        el.addEventListener('touchend', function (e) {
            if ((((swipe_det.eX - min_x > swipe_det.sX) || (swipe_det.eX + min_x < swipe_det.sX)) && ((swipe_det.eY < swipe_det.sY + max_y) && (swipe_det.sY > swipe_det.eY - max_y) && (swipe_det.eX > 0)))) {
                if (swipe_det.eX > swipe_det.sX) direc = -1;
                else direc = 1;
            }
            if (direc !== "") {
                if (typeof callback === 'function') callback(direc, el);
            }
            direc = "";
            swipe_det.sX = 0;
            swipe_det.sY = 0;
            swipe_det.eX = 0;
            swipe_det.eY = 0;
        }, false);
    };

toggleLogoClass();
if (slider.section) {
    updateSwipeArrows();
}

if (mobileMenu) {
    mobileMenu.addEventListener('click', function (e) {
        e.preventDefault();
        body.classList.add('menu-opened');

    });
}
if (closeMenu) {
    closeMenu.addEventListener('click', function (e) {
        e.preventDefault();
        body.classList.remove('menu-opened');
    });
}

if (slider.container) {
    detectSwipe(slider.container, swipePartners)
}

document.querySelectorAll('a[href="' + location.pathname + '"]').forEach(function (elem) {
    elem.classList.add('current-link');
});

window.addEventListener('scroll', toggleLogoClass);

for (var i = 0; i < slider.arrows.length; i++) {
    slider.arrows[i].addEventListener('click', function (e) {
        swipePartners(+e.target.dataset.direction);
    });
}